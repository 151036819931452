import React from 'react';

import { AiFillCaretRight } from "react-icons/ai";
import { GrBike } from "react-icons/gr";
import { FaSwimmingPool } from "react-icons/fa";
import { MdFreeBreakfast } from "react-icons/md";
import { GiForest } from "react-icons/gi";
import { IoIosBed } from "react-icons/io";

import './Service.css';

const data = {
    "service1": "Maison située dans la zone de la forêt , à proximité du golf et du tennis club, à 1 km du lac et du centre-ville d’Hossegor.",
    "service2": "Les chambres se trouvent sur un même niveau au rez-de-jardin. Un salon / salle à manger équipé (23 m2) est à la disposition exclusive des hôtes. L’ensemble peut être privatisé.",
    "service3": "Accès à la piscine chauffée, aux terrasses et jardin.",
    "service4": "Parking (équipé prise électrique) et abri pour vélos.",
    "service5": "Petits déjeuners servis dans la salle manger, pas de service de table d’hôtes mais possibilité d’organiser avec des producteurs locaux sélectionnés des en-cas/dégustation."
}

const VertLine = () => {
    return (
        <div className="wrapper-line-service">
            <div className="line-service">
            </div>
        </div>
    )
}

const Service = ({serviceRef}) => {
    return (
        <div className="service-container-parent" ref={serviceRef}>
            <div className="service-container">
                <h2 className="title-text-services">Les Services</h2>
                <div className="services-list">
                    <div className="services-row">
                        <div className="services-item-forest">
                            <GiForest />
                        </div>

                        <div className="services-item">
                            <p>
                                {data["service1"]}
                            </p>
                        </div>
                    </div>
                    <div className="services-row">
                        <div className="services-item-bed">
                            <IoIosBed />
                        </div>
                        <div className="services-item">
                            <p>
                                {data["service2"]}
                            </p>
                        </div>
                    </div>
                    <div className="services-row">
                        <div className="services-item">
                            <div className="center-icon-pool">
                                <FaSwimmingPool />
                            </div>
                        </div>
                        <div className="services-item">
                            <p>
                                {data["service3"]}
                            </p>
                        </div>
                    </div>
                    <div className="services-row">
                        <div className="services-item">
                            <div className="center-icon-bike">
                                <GrBike size={20}/>
                            </div>
                        </div>
                        <div className="services-item">
                            <p>
                                {data["service4"]}
                            </p>
                        </div>
                    </div>
                    <div className="services-row">
                        <div className="services-item-breakfast">
                            <MdFreeBreakfast />
                        </div>
                        <div className="services-item">
                            <p>
                                {data["service5"]}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="container-services">
                    <div className="container-services1">
                        <AiFillCaretRight />
                        <div className="container-item-service">
                            <p>
                                {data["service1"]}
                            </p>
                        </div>
                        <div className="container-item-service">
                            <p>
                                {data["service2"]}
                            </p>
                        </div>
                        <div className="container-item-service">
                            <p>
                                {data["service3"]}
                            </p>
                        </div>
                    </div>
                    <div className="container-services2">
                        <div className="container-item-service">
                            <p>
                                {data["service4"]}
                            </p>
                        </div>
                        <div className="container-item-service">
                            <p>
                                {data["service5"]}
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Service;