import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiX } from 'react-icons/bi';
import { BsList } from 'react-icons/bs';
import './Navbar.css';

const Navbar = (props) => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // show the button if the window is enough larger
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const clickNav = (executeScroll) => {
         executeScroll();
         closeMobileMenu();
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link
                        className="navbar-logo"
                        onClick={() => clickNav(props.executeScrollHome)}
                    >
                        LANDARRA
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        {
                            click ? <BiX/> : <BsList />
                        }
                    </div>
                    <ul className={click ? 'nav-menu active': 'nav-menu'}>
                        <li className='nav-item'>
                            <Link
                            className='nav-links'
                            onClick={() => clickNav(props.executeScrollHouse)}
                            >
                                La Maison
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className='nav-links'
                                onClick={() => clickNav(props.executeScrollRooms)}
                            >
                                Les Chambres
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className='nav-links'
                                onClick={() => clickNav(props.executeScrollService)}
                            >
                                Les Services
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className='nav-links'
                                onClick={() => clickNav(props.executeScrollLandes)}
                            >
                                Hossegor et les Landes
                            </Link>
                        </li>
                        <li>
                            <Link
                                className='nav-links'
                                onClick={() => clickNav(props.executeScrollContacts)}
                            >
                                Nous Contacter
                            </Link>
                        </li>

                    </ul>
                </div>

            </nav>
        </>
    );
}

export default Navbar
