import React from 'react';

// image to display
import exterieur2 from '../images/outside/exterieur2.jpeg'
import exterieur3 from '../images/outside/exterieur3.jpeg'
import exterieur4 from '../images/outside/exterieur4.jpeg'
import exterieur5 from '../images/outside/exterieur5.jpeg'
import exterieur6 from '../images/outside/maison3.jpg'

import shared1 from '../images/shared/sejour1.jpg'
import shared2 from '../images/shared/sejour2.jpg'

import "./House.css";

const ImageHouse = () => {
    return (
        <section className="gallery">
            <div className="container2">
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">
                            <img src={exterieur2}></img>
                        </div>
                    </div>
                </div>
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">
                            <img src={exterieur3}></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container1">
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">
                            <img src={exterieur4}></img>
                        </div>
                    </div>
                </div>
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">
                            <img src={exterieur6}></img>
                        </div>
                    </div>
                </div>
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">
                            <img src={exterieur5}></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container3">
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">   
                            <img src={shared1}></img>
                        </div>
                    </div>
                </div>
                <div className="gallery-container">
                    <div className="gallery-item">
                        <div className="image">   
                            <img src={shared2}></img>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

const House = ({houseRef}) => {
    return (
        <div ref={houseRef}>
            <div>
                <h2 className="title-text-house">
                    La Maison
                </h2>
            </div>
            <div>
                <ImageHouse />
            </div>
        </div>
    )
}

export default House;
