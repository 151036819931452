import React from 'react';

import "./Rooms.css";

import Card from './Card';

import room1Overview from './../images/room1/rosia_1.jpeg'
import room1Overview2 from './../images/room1/rosia_2.jpeg'
import bathroom from './../images/room1/rosia_3.jpeg'

import room2Overview from './../images/room2/serra_1.jpeg'
import room2Overview2 from './../images/room2/serra_2.jpeg'
import room2Bath from './../images/room2/serra_3.jpeg'

import room3Overview from './../images/room3/mareja_1.jpeg'
import room3Overview2 from './../images/room3/mareja_2.jpeg'
import room3Overview3 from './../images/room3/mareja_3.jpeg'

const data = {
    "title": ["Rosia", "Sèrra", "Mareja"],
    "description": {
        "desc1": ["Chambre de 25 m2", "Douche à l’italienne, toilettes, sèche-serviettes, sèche-cheveux", "Terrasse extérieure couverte 5 m2", "Lit double 160 cm X 200 cm", "Bureau, fauteuil, banquette 90 cm x 190 cm", "Wifi gratuit"],
        "desc2": ["Chambre de 20 m2", "Douche à l’italienne, toilettes, sèche-serviettes, sèche-cheveux", "Terrasse extérieure partagée", "Lit double 160 cm x 200 cm", "Bureau, fauteuil", "Wifi Gratuit"],
        "desc3": ["Chambre de 19 m2", "Douche à l’italienne, toilettes, sèche-serviettes, sèche-cheveux", "Terrasse extérieure couverte 5 m2", "Lit double 160 cm X 200 cm, peut être aménagé en deux lits simples", "Bureau", "Wifi gratuit"]
    },
}

const RoomCards = () => {
    return (
        <div className="small-rooms-container">
            <div className="rooms-top-container">
                <Card title={data["title"][0]} description={data["description"]["desc1"]} firstImg={room1Overview} images={[room1Overview2, bathroom]}/>
                <Card title={data["title"][1]} description={data["description"]["desc2"]} firstImg={room2Overview} images={[room2Overview2, room2Bath]}/>
                <Card title={data["title"][2]} description={data["description"]["desc3"]} firstImg={room3Overview} images={[room3Overview2, room3Overview3]}/>
            </div>
        </div>
    )
}

const Rooms = ({roomsRef}) => {
    return (
        <div ref={roomsRef}>
            <h2 className="title-text-rooms">
                Les Chambres
            </h2>
            <div>
                <RoomCards/>
            </div>
        </div>
    )
}

export default Rooms;