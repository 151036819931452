import React from 'react';

import l1 from '../images/hossegor/surf.jpg';
import l2 from '../images/hossegor/plage-centrale.jpg';
import l3 from '../images/hossegor/landes-foret.jpg';
import l4 from '../images/hossegor/landes2.jpg';

import "./Landes.css";

const Landes = ({landesRef}) => {
    return (
        <div ref={landesRef} className="landes">
            <h2 className="title-text-landes">
                Hossegor et les Landes
            </h2>
                <div className="landes-text-container">
                    <p>
                        A 20 km du pays Basque, sur la côte sud des Landes (la « Landifornia »), Hossegor est une station balnéaire réputée à l’architecture et l’environnement préservés. Elle bénéficie d’une situation exceptionnelle entre la forêt landaise , les grandes plages de la côte sauvage et son lac marin, vestige d’un ancien lit de l’Adour. Le célèbre gouf (canyon sous-marin) d’Hossegor/Capbreton offre un spot exceptionnel recherché par les surfeurs.
                    </p>
                </div>
                <div>
                    <section className="gallery-hossegor">
                        <div className="container1-hossegor">
                            <div className="gallery-item-hossegor">
                                    <div className="image-hossegor">
                                        <img src={l1}></img>
                                    </div>
                            </div>
                            <div className="gallery-item-hossegor">
                                    <div className="image-hossegor">
                                        <img src={l2}></img>
                                    </div>
                            </div>
                        </div>

                        <div className="container2-hossegor">
                            <div className="gallery-item-hossegor">
                                    <div className="image-hossegor">
                                        <img src={l3}></img>
                                    </div>
                            </div>
                            <div className="gallery-item-hossegor">
                                    <div className="image-hossegor">
                                        <img src={l4}></img>
                                    </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div>
                    <div className="wrapper-line-welcome">
                        <div className="line-welcome">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landes;