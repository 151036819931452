import React from 'react';
import { Link } from 'react-router-dom';

import "./HomeModal.css"

const HomeModal = (props) => {
    return (
        <div className="modal-container">
            <div className="modal-content">
                <p className="text-modal-title">LANDARRA à Hossegor</p>
                <p className="text-modal-subtitle">Chambres d'hôtes</p>
                <div className="wrapper">
                    <div className="line-modal"></div>
                    <div className="button-holder">
                        <div className="btn">
                            <Link
                                className="text-content-modal"
                                onClick={props.executeScrollContacts}
                            >
                                Nous Contacter
                            </Link>
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeModal;
