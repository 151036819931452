import React from 'react';

import "./Welcome.css";

const Welcome = () => {
    return (
        <div>
            <div className="container-welcome">
                <div className="container-item-welcome">
                    <div className="title-welcome">
                        <h2 className="title-welcome-content">
                            Bienvenue
                            à Landarra
                        </h2>
                    </div>
                </div>
                <div className="container-item-welcome">
                    <div className="text-content-welcome">
                        <p>
                            Nathalie et Jean vous proposent trois chambres d’hôtes indépendantes à la décoration moderne et chaleureuse, dans un cadre intimiste, arboré et préservé.
                        </p>
                    </div>
                </div>
            </div>
            <div className="wrapper-line-welcome">
                <div className="line-welcome">
                </div>
            </div>
        </div>
    )
}

export default Welcome;