import React from 'react';

import "./HomeImgSection.css";

const HomeImgSection = ({homeRef}) => {
    return (
        <div className="container-img" ref={homeRef}>
        </div>
    );
}

export default HomeImgSection;