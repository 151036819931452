import React, { useRef } from 'react';

import HomeImgSection from './../HomeImgSection';
import Welcome from './../Welcome';
import House from './../House';
import HomeModal from './../HomeModal';
import Rooms from './../Rooms';
import Navbar from './../Navbar';
import Landes from './../Landes';
import Contact from './../Contact';
import Footer from './../Footer';
import Service from './../Service';


const Home = () => {
    // ref
    const homeRef = useRef(null);
    const executeScrollHome = () => {
        homeRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const houseRef = useRef(null);
    const executeScrollHouse = () => {
        console.log("scrolling");
        houseRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const roomsRef = useRef(null);
    const executeScrollRooms = () => {
        roomsRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const serviceRef = useRef(null);
    const executeScrollService = () => {
        serviceRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const landesRef = useRef(null);
    const executeScrollLandes = () => {
        landesRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const contactsRef = useRef(null);
    const executeScrollContacts = () => {
        contactsRef.current.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <>
            <Navbar
                executeScrollHome={executeScrollHome}
                executeScrollHouse={executeScrollHouse}
                executeScrollRooms={executeScrollRooms}
                executeScrollService={executeScrollService}
                executeScrollLandes={executeScrollLandes}
                executeScrollContacts={executeScrollContacts}
            />
            <HomeImgSection homeRef={homeRef}/>
            <HomeModal executeScrollContacts={executeScrollContacts}/>
            <Welcome />
            <House houseRef={houseRef} />
            <Rooms roomsRef={roomsRef} />
            <Service serviceRef={serviceRef} />
            <Landes landesRef={landesRef} />
            <Contact contactsRef={contactsRef} />
            <Footer />
        </>
    )
}

export default Home;
