import React from 'react';

import "./Footer.css";

const Footer = () => {
    return (
        <div className="footer">
            <div className="line"></div>
            <p className="copyright">Copyright © 2021, All Right Reserved Landarra</p>
        </div>
    )
}

export default Footer;