import React, {useState} from 'react';

import './Card.css';

const Modal = ({ src, alt, caption, onClose }) => {
    return (
        <div className="modal-rooms">
            <span className="close-rooms" onClick={onClose}>
                &times;
            </span>
            <div className="modal-rooms-container-img">
                <div className="modal-rooms-item-img">
                    <img className="modal-rooms-content" src={src[0]} alt={alt} />
                </div>
                <div className="modal-rooms-item-img">
                    <img className="modal-rooms-content" src={src[1]} alt={alt} />
                </div>
            </div>
            {caption.length > 0 && <div className="caption"></div>}
        </div>
    )
}

const Card = (props) => {
    const imgs = props.images;
    const firstImg = props.firstImg;
    const [isOpen, setIsOpen] = useState(false);
    const showModal = () => setIsOpen(true);
    const hideModal = () => setIsOpen(false);

    return (
        <div className="container">
            <div className="property-card" onClick={showModal}>
                <div className="property-image" style={{ backgroundImage: `url(${firstImg})` }}>
                    <div className="property-image-title">
                    </div>
                </div>
                <div className="property-description">
                    <h5>{props.title}</h5>
                    <ul>
                        {
                            props.description.map((val, idx) => {
                                return <p>{val}</p>
                            })
                        }
                    </ul>
                </div>
            </div>
            {isOpen && (
                <Modal
                    src={imgs}
                    alt="snow"
                    caption="caption"
                    onClose={hideModal}
                />
            )}
        </div>
    )
}

export default Card;