import React from 'react';

import "./Contact.css";

const Contact = ({contactsRef}) => {
    return (
        <div className="contact-holder" ref={contactsRef}>
            <div className="contacts-container-parent">
                <div className="contacts-container">
                    <h2 className="title-contact">
                        Nous Contacter
                    </h2>
                    <p>Nathalie et Jean Guillaume</p>
                    <p>Jguillaume.ce@orange.fr</p>
                    <p>+33607896721</p>
                    <p>107 impasse des loirs 40150 Hossegor</p>
                </div>
            </div>
        </div>
    )
}

export default Contact;